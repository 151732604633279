import {
  House,
  CleaningServices,
  DryCleaning,
  SupervisedUserCircle,
  Business,
} from "@mui/icons-material";
export const DummyData = [
  {
    _id: "657ececf593d3357305ab9a4",
    name: "Home Cleaning",
    image: "homeCleaning",
    price: 25,
    pricing_type: "hour",
    price_per_maid: 1,
    min_hours: 4,
    max_hours: 10,
    min_maids: 1,
    max_maids: 5,
    order: 1,
    icon: <House sx={{ fontSize: "42px", color: "#fff" }} />,
  },
  {
    _id: "657ececf593d3357305ab9a5",
    name: "Deep Cleaning",
    image: "deepCleaning",
    price: 0.5,
    pricing_type: "square foot",
    price_per_maid: 1,
    min_hours: 5,
    max_hours: 10,
    min_maids: 1,
    max_maids: 4,
    order: 2,
    icon: <CleaningServices sx={{ fontSize: "42px", color: "#fff" }} />,
  },
  {
    _id: "657ececf593d3357305ab9a6",
    name: "Folding & Ironing Service",
    image: "foldingIroning",
    price: 25,
    pricing_type: "hour",
    price_per_maid: 5,
    min_hours: 4,
    max_hours: 10,
    min_maids: 1,
    max_maids: 2,
    order: 3,
    icon: <DryCleaning sx={{ fontSize: "42px", color: "#fff" }} />,
  },
  {
    _id: "657ececf593d3357305ab9a7",
    name: "AirBnB/Rental Home Cleaning",
    image: "airBnB",
    price: 0.5,
    pricing_type: "square foot",
    price_per_maid: 5,
    min_hours: 1,
    max_hours: 3,
    min_maids: 1,
    max_maids: 2,
    order: 4,
    icon: <SupervisedUserCircle sx={{ fontSize: "42px", color: "#fff" }} />,
  },
  {
    _id: "657ececf593d3357305ab9a8",
    name: "Office Cleaning",
    image: "officeCleaning",
    price: 0,
    pricing_type: "Contact us",
    price_per_maid: 0,
    min_hours: 0,
    max_hours: 0,
    min_maids: 0,
    max_maids: 0,
    order: 5,
    icon: <Business sx={{ fontSize: "42px", color: "#fff" }} />,
  },
];
