import { Fragment } from "react";
import { Colors } from "./App/Assets/Styles/Colors";
import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminRoute from "./App/Routes/admin.routes";
import PublicRoutes from "./App/Routes/public.routes";
import useAuth from "./App/Hooks/useAuth";
import { Layout } from "./App/Layout/Layout";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    h1: {
      fontSize: "72px",
    },
    h2: {
      fontSize: "60px",
    },
    h3: {
      fontSize: "48px",
    },
    h4: {
      fontSize: "36px",
    },
    h5: {
      fontSize: "28px",
    },
    h6: {
      fontSize: "20px",
    },
    body1: {
      fontSize: "18px",
    },
    subtitle1: {
      fontSize: "16px",
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "12px",
    },
  },
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },
});

function App() {
  const { user } = useAuth();
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* <Route element={user ? <Layout /> : <Navigate to={"/login"} />}>
              {AdminRoute.map((route, i) => (
                <Route key={i} path={route.path} element={route.component} />
              ))}
            </Route> */}
            <Route>
              {PublicRoutes.map((route, i) => (
                <Route key={i} path={route.path} element={route.component} />
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
