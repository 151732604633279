import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Container, Typography, Paper, Button } from "@mui/material";
import { Colors } from "../../Assets/Styles/Colors";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function OrderDetails() {
  const { state } = useLocation();
  console.log("🚀 ~ file: OrderDetails.js:9 ~ OrderDetails ~ state:", state);

  const navigate = useNavigate();

  let currentMonth = moment().month(); // Get the current month index
  let currentYear = moment().year();
  const formattedDates = state?.frequency?.dates?.map((day) => {
    if (day > 30) {
      // Handle overflow to the next month and year
      currentMonth++;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear++;
      }
      day -= 30;
    }

    // Format the date
    return moment(
      `${day}-${currentMonth + 1}-${currentYear}`,
      "DD-MM-YYYY"
    ).format("DD-MM-YYYY");
  });

  return (
    <Box sx={{ mt: 5 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box component={Paper}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "230px",
                }}
              >
                <Typography variant={"h5"}>Customer Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Name:</Typography>
                  <Typography>{state?.contact?.name}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Email Address:</Typography>
                  <Typography>{state?.contact?.email}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Phone Number:</Typography>
                  <Typography>+971{state?.contact?.number}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Address:</Typography>
                  <Typography>
                    {state?.contact?.address},{state?.contact?.city}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box component={Paper}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  height: "230px",
                }}
              >
                <Typography variant={"h5"}>Summary Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Service Name:</Typography>
                  <Typography>{state?.summary?.name}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Number of Hours:</Typography>
                  <Typography>
                    {state?.summary?.hours === null
                      ? "-"
                      : state?.summary?.hours}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Number of Sqft:</Typography>
                  <Typography>
                    {state?.summary?.sqft === null ? "-" : state?.summary?.sqft}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Number of Maids:</Typography>
                  <Typography>{state?.summary?.maids}</Typography>
                </Box>
                <Box
                  sx={{
                    display:
                      state?.summary?.name === "Home Cleaning" ||
                      state?.summary?.name === "Folding & Ironing Service"
                        ? "flex"
                        : "none",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Cleaning Material:</Typography>
                  <Typography>
                    {state?.summary?.cleaning_material === true ? "Yes" : "No"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: 600 }}>Notes:</Typography>
                  <Typography>
                    {state?.summary?.notes === "" ? "-" : state?.summary?.notes}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box component={Paper} sx={{ height: "100%" }}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant={"h5"}>Frequency Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Dates:</Typography>
                  <Typography>
                    {state?.frequency?.dates?.length === 0
                      ? "-"
                      : formattedDates}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Frequency:</Typography>
                  <Typography>{state?.frequency?.type}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Days:</Typography>
                  <Typography>
                    {state?.frequency?.type === "once"
                      ? "-"
                      : state?.frequency?.days?.join(",  ")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Time Slot:</Typography>
                  <Typography>
                    {state?.frequency?.slot?.length === 0
                      ? "-"
                      : state?.frequency?.slot?.join("")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Months:</Typography>
                  <Typography>
                    {state?.frequency?.months?.length === 0
                      ? "-"
                      : state?.frequency?.months?.join(", ")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Weeks:</Typography>
                  <Typography>{state?.frequency?.weeks ?? "-"}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box component={Paper}>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography variant={"h5"}>Payment Details</Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Price:</Typography>
                  <Typography>{state?.payment?.total}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Material Price:</Typography>
                  <Typography>{state?.payment?.material}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Taxable:</Typography>
                  <Typography>
                    {parseFloat(
                      state?.payment?.total + state?.payment?.material
                    ).toFixed(2)}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>VAT 5%:</Typography>
                  <Typography>
                    {parseFloat(
                      (state?.payment?.total + state?.payment?.material) * 0.05
                    ).toFixed(2)}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Payment Method:</Typography>
                  <Typography>
                    {state?.payment?.method === ""
                      ? "-"
                      : state?.payment?.method}
                  </Typography>
                </Box>
                {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Status:</Typography>
                  <Typography>{state?.payment?.status}</Typography>
                </Box> */}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Total:</Typography>
                  <Typography>
                    {parseFloat(
                      parseFloat(
                        state?.payment?.total + state?.payment?.material
                      ) +
                        parseFloat(
                          state?.payment?.total + state?.payment?.material
                        ) *
                          0.05
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ pb: 2 }}>
              <Button
                variant={"contained"}
                sx={{
                  ":hover": {
                    backgroundColor: Colors.primary,
                    color: Colors.white,
                  },
                  color: Colors.white,
                }}
                onClick={() => navigate("/orders")}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OrderDetails;
