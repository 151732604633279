export const Colors = {
  primary: "#004687",
  secondary: "#000000",
  white: "#ffffff",
  smokeWhite: "#FBFBFB",
  black: "#000000",
  blueShade: "#277d98",
  grey: "#c4c4c4",
  greyText: "#9b9b9b",
  danger: "#D32F2F",
  smokeGreen: "#fbfbfb",
};
