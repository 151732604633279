import Login from "../View/Auth/Login";
import Booking from "../View/Booking/Booking";

const PublicRoutes = [
  {
    path: "/",
    component: <Booking />,
  },
  // {
  //   path: "/login",
  //   component: <Login />,
  // },
];

export default PublicRoutes;
