export const Images = {
  homeCleaning: require("./HomeCleaning.jpeg"),
  deepCleaning: require("./DeepCleaning.jpeg"),
  airBnB: require("./AirBnB.jpeg"),
  foldingIroning: require("./FoldingIroning.jpeg"),
  officeCleaning: require("./OfficeCleaning.jpeg"),
  logo: require("./logo (2).png"),
  bgImage: require("./home-page-background.jpeg"),
  avatar: require("./avatar.png"),
  bookingArrow: require("./booking-arrow.png"),
};
