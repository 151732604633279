import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Container,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  CardMedia,
  IconButton,
  Divider,
  Checkbox,
  ButtonGroup,
  FormGroup,
  FormHelperText,
  useTheme,
  MenuItem,
  TextField,
  Modal,
} from "@mui/material";
import {
  CheckCircleOutline,
  Check,
  KeyboardArrowLeft,
  Home,
  KeyboardArrowUp,
  TaskAlt,
  CalendarMonth,
  ChangeCircle,
  Payments,
  CreditCard,
  AccountBalance,
  ChevronLeft,
  ChevronRight,
  Paid,
} from "@mui/icons-material";
import { Images } from "../../Assets/Images/Images";
import { Colors } from "../../Assets/Styles/Colors";
import InputField from "../../Components/InputField/InputField";
import { useForm } from "react-hook-form";
import "../../../App.css";
import { DummyData } from "./DummyData";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%", // Default width

  "@media (max-width: 768px)": {
    width: "80%", // Adjust width for screens smaller than 768px
  },

  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  height: "500px",
  overflowY: "scroll",
  zIndex: "99999999",
  p: 4,

  // Scrollbar styling
  "&::-webkit-scrollbar": {
    width: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#1FC6C7",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
  },
  "scrollbar-width": "12px",
  "scrollbar-color": "#1FC6C7 #f1f1f1",
};

function Booking() {
  const steps = [
    "Choose Service",
    "Choose Frequency",
    "Customer Details",
    "Payment Details",
  ];
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const timeSlots = [
    {
      id: 1,
      slot: "Morning",
      arrivalTime: "(9am-11am)",
    },
    {
      id: 2,
      slot: "Afternoon",
      arrivalTime: "(12pm-2pm)",
    },
    {
      id: 3,
      slot: "Evening",
      arrivalTime: "(4pm-6pm)",
    },
  ];
  const paymentOptions = [
    {
      id: 1,
      option: "Bank Transfer",
      icon: <AccountBalance sx={{ fontSize: "32px", color: Colors.primary }} />,
    },
    {
      id: 2,
      option: "Credit Card",
      icon: <CreditCard sx={{ fontSize: "32px", color: Colors.primary }} />,
    },
    {
      id: 3,
      option: "Cash",
      icon: <Payments sx={{ fontSize: "32px", color: Colors.primary }} />,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [selectedService, setSelectedService] = useState(null);
  const [bedroom, setBedroom] = useState(3);
  const [bathroom, setBathroom] = useState(2);
  const [bedroomPrice, setBedroomPrice] = useState(0);
  const [bathroomPrice, setBathroomPrice] = useState(0);
  const [numberOfMaids, setNumberOfMaids] = useState(1);
  const [noOfSqFt, setNoOfSqFt] = useState(1);
  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedFrequency, setSelectedFrequency] = useState("Once");
  const [materialPrice, setMaterialPrice] = useState(50);
  const [selectedRecurringWeeks, setSelectedRecurringWeeks] = useState(1);
  const [price, setPrice] = useState(0);
  const [taxable, setTaxable] = useState(0);
  const [material, setMaterial] = useState(false);
  const [tAndC, setTAndC] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const VAT = taxable * 0.05;
  const total = (taxable + VAT).toFixed(2);
  const theme = useTheme();

  const submitForm = async (formData) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedFrequency("");
    setSelectedDay([]);
    setSelectedRecurringWeeks(1);
    setSelectedDate([]);
    setSelectedTimeSlot(null);
    setSelectedService(null);
  };

  const handleMaidDecrease = () => {
    if (numberOfMaids > 1) {
      setNumberOfMaids((prevValue) => prevValue - 1);
    }
  };

  const handleMaidIncrease = () => {
    setNumberOfMaids((prevValue) => prevValue + 1);
  };

  const handleBedroomIncrease = () => {
    setBedroom((prevValue) => prevValue + 1);
  };

  const handleBedroomDecrease = () => {
    if (bedroom > 1) {
      setBedroom((prevValue) => prevValue - 1);
    }
  };

  const handleBathroomIncrease = () => {
    setBathroom((prevValue) => prevValue + 1);
  };

  const handleBathroomDecrease = () => {
    if (bathroom > 1) {
      setBathroom((prevValue) => prevValue - 1);
    }
  };

  const handleDayToggle = (day) => {
    setSelectedDay((prevSelected) => {
      if (prevSelected.includes(day)) {
        return prevSelected.filter((item) => item !== day);
      } else {
        return [...prevSelected, day];
      }
    });
  };

  const handleTimeSlot = (e) => {
    const value = e.target.value;
    const timeSlot = timeSlots.find((item) => item.id === value);
    setSelectedTimeSlot(timeSlot);
  };

  const handleCheckboxChange = (e) => {
    if (tAndC === true) {
      setTAndC(false);
      setSubmitDisabled(true);
    } else {
      setOpen(e.target.checked);
    }
  };

  const handleTerms = () => {
    setSubmitDisabled(false);
    setTAndC(true);
    setOpen(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (
      selectedService?.pricing_type === "hour" &&
      selectedFrequency === "Once"
    ) {
      const totalMaterialPrice = 50;
      const totalPrice = selectedService?.price * numberOfMaids;
      setMaterialPrice(totalMaterialPrice);
      setPrice(totalPrice);
      setTaxable(totalPrice);
      if (material === true) {
        setTaxable(totalPrice + totalMaterialPrice);
      }
    }
    if (
      selectedService?.pricing_type === "hour" &&
      selectedFrequency === "Weekly"
    ) {
      const length = selectedDay.length;
      const totalMaterialPrice =
        (selectedDay.length > 0 ? 50 * selectedDay.length : 50) *
        selectedRecurringWeeks;
      const totalPrice =
        selectedService?.price *
        numberOfMaids *
        (length === 0 ? 1 : length) *
        selectedRecurringWeeks;
      setMaterialPrice(totalMaterialPrice);
      setPrice(totalPrice);
      setTaxable(totalPrice);
      if (material === true) {
        setTaxable(totalPrice + totalMaterialPrice);
      }
    }
    if (
      selectedService?.pricing_type === "square foot" &&
      selectedFrequency === "Once"
    ) {
      const totalMaterialPrice = 50;
      const totalBedroomPrice = 10 * bedroom;
      const totalBathroomPrice = 10 * bathroom;
      setMaterialPrice(totalMaterialPrice);
      setBedroomPrice(totalBedroomPrice);
      setBathroomPrice(totalBathroomPrice);
      setTaxable(totalBedroomPrice + totalBathroomPrice);
      if (material === true) {
        setTaxable(totalBedroomPrice + totalBathroomPrice + totalMaterialPrice);
      }
    }
    if (
      selectedService?.pricing_type === "square foot" &&
      selectedFrequency === "Weekly"
    ) {
      const length = selectedDay.length;
      const totalMaterialPrice =
        (selectedDay.length > 0 ? 50 * selectedDay.length : 50) *
        selectedRecurringWeeks;
      const totalBedroomPrice =
        10 * bedroom * (length === 0 ? 1 : length) * selectedRecurringWeeks;
      const totalBathroomPrice =
        10 * bathroom * (length === 0 ? 1 : length) * selectedRecurringWeeks;
      setMaterialPrice(totalMaterialPrice);
      setBedroomPrice(totalBedroomPrice);
      setBathroomPrice(totalBathroomPrice);
      setTaxable(totalBedroomPrice + totalBathroomPrice);
      if (material === true) {
        setTaxable(totalBedroomPrice + totalBathroomPrice + totalMaterialPrice);
      }
    }
    if (
      selectedService?.pricing_type === "Contact us" &&
      selectedFrequency === "Once"
    ) {
      const totalMaterialPrice = 50;
      const totalPrice = 0.5 * noOfSqFt;
      setMaterialPrice(totalMaterialPrice);
      setPrice(totalPrice);
      setTaxable(totalPrice);
      if (material === true) {
        setTaxable(totalPrice + totalMaterialPrice);
      }
    }
    if (
      selectedService?.pricing_type === "Contact us" &&
      selectedFrequency === "Weekly"
    ) {
      const length = selectedDay.length;
      const totalMaterialPrice =
        (selectedDay.length > 0 ? 50 * selectedDay.length : 50) *
        selectedRecurringWeeks;
      const totalPrice =
        0.5 * noOfSqFt * (length === 0 ? 1 : length) * selectedRecurringWeeks;
      setMaterialPrice(totalMaterialPrice);
      setPrice(totalPrice);
      setTaxable(totalPrice);
      if (material === true) {
        setTaxable(totalPrice + totalMaterialPrice);
      }
    }
  }, [
    selectedFrequency,
    selectedDay,
    numberOfMaids,
    bedroom,
    bathroom,
    bedroomPrice,
    bathroomPrice,
    noOfSqFt,
    selectedRecurringWeeks,
    material,
  ]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${Images.bgImage})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        height: {
          md: "100%",
          sm: "100%",
          xs: "100%",
        },
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: "none  !important", zIndex: 91300 }}
        maxWidth="md"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Terms & Conditions
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ol>
              <li>
                In case the scope of the job is found to be more extensive after
                the inspection upon arrival, we might need extra equipment /
                time and the pricing might differ.
              </li>
              <li>
                Client may cancel or adjust without any charges: the time of a
                cleaning visit/s by giving at least 24 hours advanced notice.
              </li>
              <li>
                Kindly take note that a cancellation fee amounting to 50% shall
                be levied should you opt to cancel your booking within 12 hours
                of the pre-scheduled appointment. In the event of an on the spot
                cancellation, an entire 100% cancellation fee shall be applied.
              </li>
              <li>
                You agree to pay the full price of the cleaning visit in the
                event of an on the spot cancellation, lock-out, no one home to
                let them in or a problem with your keys.
              </li>
              <li>
                Proceeding with your booking confirms your acceptance to Coco
                Prime terms and conditions.
              </li>
            </ol>
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleTerms}>Accept</Button>
          </Box>
        </Box>
      </Modal>
      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "#fff",
          opacity: 0.9,
        }}
      />
      {/* Overlay */}

      {/* Header and Stepper */}
      <Box
        sx={{
          backgroundColor: "#fff",
          position: "relative",
          boxShadow: "0px 0px 5px 1px #929292",
        }}
      >
        {/* Header */}
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: { sm: "15px", xs: "15px" },
              alignItems: "center",
              p: "10px 15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "15px",
              }}
            >
              <IconButton
                sx={{ display: { md: "none", sm: "flex", xs: "flex" } }}
                // onClick={handleBack}
              >
                <KeyboardArrowLeft sx={{ color: Colors.primary }} />
              </IconButton>
              <Box component={"a"} href="#">
                <CardMedia
                  component={"img"}
                  src={Images.logo}
                  sx={{ width: "100px", height: "100px", objectFit: "contain" }}
                />
              </Box>
            </Box>
            <Typography
              variant="h5"
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: Colors.primary,
                display: { md: "block", sm: "none", xs: "none" },
              }}
            >
              {steps[activeStep]}
            </Typography>
            <Box
              onClick={() => {
                setActiveStep(0);
                setSelectedTimeSlot(null);
                setSelectedFrequency("Once");
                setSelectedRecurringWeeks(1);
                setSelectedDay([]);
                setSelectedDate([]);
                setNumberOfMaids(1);
              }}
              sx={{ cursor: "pointer", display: "flex" }}
            >
              <Home
                sx={{
                  color: Colors.primary,
                  fontSize: { md: "30px", sm: "20px", xs: "20px" },
                }}
              />
            </Box>
          </Box>
        </Container>
        {/* Header */}

        <Divider />
        {/* Stepper */}
        <Box sx={{ backgroundColor: "#fff" }}>
          <Box
            sx={{
              width: "100%",
              height: "34px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {steps.map((step, i) => (
              <Box
                key={i}
                sx={{
                  position: "relative",
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  backgroundColor:
                    activeStep === i ? Colors.primary : Colors.white,
                  gap: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (activeStep !== 0) {
                    setActiveStep(activeStep - 1);
                  }
                }}
              >
                <Box
                  sx={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    border:
                      activeStep === i ? "1px solid #fff" : "1px solid #000",
                    color: activeStep === i ? "#fff" : "#000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {i + 1}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: activeStep === i ? "#fff" : "#000",
                  }}
                >
                  {step}
                </Typography>
                <Box
                  sx={{
                    display: i === steps.length - 1 ? "none" : "block",
                    position: "absolute",
                    right: "-25px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    borderTop: activeStep === i ? "17px solid transparent" : 0,
                    borderBottom:
                      activeStep === i ? "17px solid transparent" : 0,
                    borderLeft:
                      activeStep === i ? `25px solid ${Colors.primary}` : 0,
                    zIndex: 1,
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        {/* Stepper */}
      </Box>
      {/* Header and Stepper */}

      {/* Body */}
      <Container>
        <Box
          component={"form"}
          onSubmit={handleSubmit(submitForm)}
          sx={{
            position: "relative",
            zIndex: 9999,
            display: "flex",
            pt: "38px",
            pb: "22px",
          }}
        >
          <Grid container spacing={2}>
            {activeStep === steps.length ? (
              <Grid item md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    gap: "20px",
                    mt: { md: "30px", xs: "0px" },
                  }}
                >
                  <Typography variant="h5">
                    Thank you for your order!
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#828282", textAlign: "center" }}
                  >
                    Your order has been received, and we appreciate your trust
                    in our servies. We'll be in touch shortly for the
                    confirmation details.
                  </Typography>
                  <CheckCircleOutline
                    sx={{ fontSize: "48px", color: Colors.primary }}
                  />
                  <Button
                    variant="contained"
                    LinkComponent={"a"}
                    href="https://cocoprimecleaning.ae/"
                    sx={{
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      ":hover": {
                        backgroundColor: Colors.secondary,
                      },
                    }}
                  >
                    Go to Website
                  </Button>
                </Box>
              </Grid>
            ) : (
              <Grid item md={12}>
                <Grid container spacing={2} justifyContent={"center"}>
                  {/* Step 1 Content Section */}
                  {activeStep === 0 && (
                    <Grid item xs={12} sx={12} md={8}>
                      <Box
                        sx={{
                          color: "#000",
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "30px",
                        }}
                      >
                        <Typography variant="h5">
                          Ready to Experience the VIP Treatment?
                        </Typography>
                        <Typography variant="subtitle1">
                          Your home is unique, and so is our approach. We're not
                          just cleaning, we're perfecting. Our detail-oriented
                          team tackles every nook and cranny, ensuring a level
                          of cleanliness that goes beyond the surface.
                        </Typography>
                        <Typography variant="body1">
                          Experience the luxury of a tailored cleaning service
                          that brings out the elegance of your space.
                        </Typography>
                        <Grid container>
                          <Grid item md={12}>
                            <Grid container justifyContent={"center"}>
                              <Grid item md={8}>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent={"center"}
                                >
                                  <Grid item md={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent={"center"}
                                    >
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Your Home, Your Rules
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Eco-Friendly & Fabulous
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent={"center"}
                                    >
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Trustworthy Crew
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Regular TLC
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item md={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent={"center"}
                                    >
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Deep-Clean Magic
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid item={6}>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "5px",
                                          }}
                                        >
                                          <Check
                                            sx={{
                                              color: Colors.primary,
                                              fontWeight: 600,
                                            }}
                                          />
                                          <Typography
                                            variant={"subtitle2"}
                                            sx={{
                                              width: "154px",
                                              textAlign: "left",
                                            }}
                                          >
                                            Eco-Chic Glamour
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            width: "100%",
                            height: "85px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="body1">
                              Let Us Sparkle Your Home!
                            </Typography>
                          </Box>
                          <CardMedia
                            component={"img"}
                            src={Images.bookingArrow}
                            height={"100%"}
                            sx={{ objectFit: "contain", width: "80%" }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  {/* Step 1 Content Section */}

                  {/* Step 1 Services Section */}
                  {activeStep === 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      sx={{
                        display: {
                          md: "block",
                          sm: "none",
                          xs: "none",
                        },
                      }}
                    >
                      <Box
                        component={Paper}
                        sx={{
                          mb: 2,
                          p: 2,
                        }}
                      >
                        <Box sx={{ mb: 1 }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            Choose Service
                          </Typography>
                        </Box>
                        <FormControl component={"fieldset"}>
                          <RadioGroup
                            value={selectedService ? selectedService._id : ""}
                            onChange={(event) => {
                              const selectedId = event.target.value;
                              const selectedObject = DummyData.find(
                                (service) => service._id === selectedId
                              );
                              setSelectedService(selectedObject);
                              if (selectedObject.pricing_type === "hour") {
                                setPrice(selectedObject.price);
                                setTaxable(selectedObject.price);
                              }
                              if (
                                selectedObject.pricing_type === "square foot"
                              ) {
                                const initalBedroomPrice = 10 * bedroom;
                                const initalBathroomPrice = 10 * bathroom;
                                setBedroomPrice(initalBedroomPrice);
                                setBathroomPrice(initalBathroomPrice);
                                setTaxable(
                                  initalBedroomPrice + initalBathroomPrice
                                );
                              }
                              if (
                                selectedObject.pricing_type === "Contact us"
                              ) {
                                setPrice(0.5);
                                setTaxable(0.5);
                              }
                            }}
                          >
                            <Grid container spacing={2}>
                              {DummyData.map((service, i) => (
                                <Grid key={i} item md={6}>
                                  <FormControlLabel
                                    value={service._id}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      width: "100%",
                                      m: 0,
                                      "& .MuiFormControlLabel-label": {
                                        width: "100%",
                                      },
                                      textAlign: "center",
                                    }}
                                    control={
                                      <Radio style={{ display: "none" }} />
                                    }
                                    label={
                                      <Fragment>
                                        <Box
                                          sx={{
                                            width: "90%",
                                            height: "65px",
                                            p: 1,
                                            backgroundColor:
                                              selectedService?._id ===
                                              service._id
                                                ? Colors.primary
                                                : "transparent",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "5px",
                                            border: `1px solid ${Colors.primary}`,
                                          }}
                                        >
                                          {React.cloneElement(service.icon, {
                                            sx: {
                                              fontSize: "42px",
                                              color:
                                                selectedService &&
                                                selectedService._id ===
                                                  service._id
                                                  ? "#fff"
                                                  : Colors.primary,
                                            },
                                          })}
                                        </Box>
                                        <Typography variant="caption">
                                          {service.name}
                                        </Typography>
                                      </Fragment>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                  {/* Step 1 Services Section */}

                  {/* Step 2 Gathering service information */}
                  {activeStep === 1 && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        component={Paper}
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: "12px",
                        }}
                      >
                        {/* Heading */}
                        <Box sx={{ textAlign: "center" }}>
                          <Typography variant={"h5"}>
                            Complete your booking
                          </Typography>
                        </Box>
                        {/* Heading */}

                        <Divider />

                        {/* Select Frequency */}
                        <Typography
                          variant="subtitle2"
                          sx={{ textAlign: "center" }}
                        >
                          How often do you need your cleaner?
                        </Typography>
                        <Box>
                          <ButtonGroup
                            variant="outlined"
                            {...register("frequency", {
                              required: !selectedFrequency
                                ? "Please select frequency"
                                : false,
                            })}
                            sx={{ width: "100%", justifyContent: "center" }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                p: "5px 25px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                border:
                                  selectedFrequency === "Once"
                                    ? `1px solid ${Colors.primary}`
                                    : `1px solid ${Colors.secondary}`,
                                ":hover": {
                                  border:
                                    selectedFrequency === "Once"
                                      ? `1px solid ${Colors.secondary}`
                                      : `1px solid ${Colors.primary}`,
                                  backgroundColor:
                                    selectedFrequency === "Once"
                                      ? Colors.secondary
                                      : Colors.primary,
                                  color: Colors.white,
                                },
                                backgroundColor:
                                  selectedFrequency === "Once"
                                    ? Colors.primary
                                    : Colors.white,
                                color:
                                  selectedFrequency === "Once"
                                    ? Colors.white
                                    : Colors.secondary,
                              }}
                              onClick={() => {
                                setSelectedDate("");
                                setSelectedTimeSlot(null);
                                setSelectedDay([]);
                                setSelectedRecurringWeeks(1);
                                setSelectedFrequency("Once");
                              }}
                            >
                              Once
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{
                                p: "5px 25px",
                                fontSize: "12px",
                                borderRadius: "4px",
                                border:
                                  selectedFrequency === "Weekly"
                                    ? `1px solid ${Colors.primary}`
                                    : `1px solid ${Colors.secondary}`,
                                ":hover": {
                                  border:
                                    selectedFrequency === "Weekly"
                                      ? `1px solid ${Colors.secondary}`
                                      : `1px solid ${Colors.primary}`,
                                  backgroundColor:
                                    selectedFrequency === "Weekly"
                                      ? Colors.secondary
                                      : Colors.primary,
                                  color: Colors.white,
                                },
                                backgroundColor:
                                  selectedFrequency === "Weekly"
                                    ? Colors.primary
                                    : Colors.white,
                                color:
                                  selectedFrequency === "Weekly"
                                    ? Colors.white
                                    : Colors.secondary,
                              }}
                              onClick={() => {
                                setSelectedTimeSlot(null);
                                setSelectedDate("");
                                setSelectedDay([]);
                                setSelectedRecurringWeeks(1);
                                setSelectedFrequency("Weekly");
                              }}
                            >
                              Weekly
                            </Button>
                          </ButtonGroup>
                          {errors?.frequency && (
                            <FormHelperText
                              sx={{
                                display: !selectedFrequency ? "block" : "none",
                              }}
                              error={errors?.frequency && true}
                            >
                              {errors?.frequency?.message}
                            </FormHelperText>
                          )}
                        </Box>
                        {/* Select Frequency */}

                        {/* Select Days */}
                        {selectedFrequency === "Weekly" && (
                          <Box>
                            <Typography variant="subtitle2">
                              Select your days
                            </Typography>
                            <FormControl
                              {...register("days", {
                                required:
                                  selectedFrequency !== "Once" &&
                                  selectedDay.length < 1
                                    ? "Please select days"
                                    : false,
                              })}
                            >
                              <FormGroup
                                sx={{
                                  flexDirection: "row",
                                  gap: "10px",
                                }}
                              >
                                {weekDays.map((item, i) => (
                                  <FormControlLabel
                                    sx={{ m: 0 }}
                                    key={i}
                                    control={
                                      <Checkbox
                                        checked={selectedDay.includes(item)}
                                        onChange={() => handleDayToggle(item)}
                                        style={{
                                          display: "none",
                                        }}
                                      />
                                    }
                                    label={
                                      <Box
                                        sx={{
                                          p: "4px 8px",
                                          width: "80px",
                                          height: "40px",
                                          cursor: "pointer",
                                          borderRadius: "4px",
                                          backgroundColor: selectedDay.includes(
                                            item
                                          )
                                            ? Colors.primary
                                            : Colors.white,
                                          color: selectedDay.includes(item)
                                            ? Colors.white
                                            : Colors.black,
                                          transition: "all .1s ease-in-out",
                                          ":hover": {
                                            backgroundColor:
                                              selectedDay.includes(item)
                                                ? Colors.secondary
                                                : Colors.primary,
                                            color: Colors.white,
                                          },
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {selectedDay.includes(item) && (
                                          <TaskAlt sx={{ fontSize: "20px" }} />
                                        )}
                                        <Typography variant="caption">
                                          {item}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                ))}
                              </FormGroup>
                            </FormControl>
                            <FormHelperText
                              sx={{
                                display:
                                  selectedDay.length < 1 ? "block" : "none",
                              }}
                              error={selectedDay.length < 1 ? true : false}
                            >
                              {errors?.days?.message}
                            </FormHelperText>
                          </Box>
                        )}
                        {/* Select Days */}

                        {/* Maid and Date */}
                        <Grid container spacing={2} alignItems={"flex-start"}>
                          {/* Select Recurring Weeks */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            display={
                              selectedFrequency === "Weekly" ? "block" : "none"
                            }
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                For how many weeks you want service?
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: `1px solid ${Colors.grey}`,
                                  borderRadius: "4px",
                                  height: "40.9px",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    if (selectedRecurringWeeks > 1) {
                                      setSelectedRecurringWeeks(
                                        (prevValue) => prevValue - 1
                                      );
                                    }
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "4px 0px 0px 4px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    p: "5px 8px !important",
                                    height: "100%",
                                    borderRight: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "4px 0px 0px 4px",
                                  }}
                                >
                                  -
                                </Button>
                                <TextField
                                  size="small"
                                  value={selectedRecurringWeeks}
                                  inputProps={{
                                    readOnly: true,
                                    min: 1,
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    flex: 1,
                                    width: "100%",
                                    "& fieldset": { border: "none" },
                                  }}
                                />
                                <Button
                                  onClick={() => {
                                    setSelectedRecurringWeeks(
                                      (prevValue) => prevValue + 1
                                    );
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "0px 4px 4px 0px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    height: "100%",
                                    p: "5px 8px !important",
                                    borderLeft: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "0px 4px 4px 0px",
                                  }}
                                >
                                  +
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          {/* Select Recurring Weeks */}

                          {/* Select Maids */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            display={
                              selectedService?.name === "Home Cleaning" ||
                              selectedService?.name ===
                                "Folding & Ironing Service"
                                ? "block"
                                : "none"
                            }
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                Number of Maids
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: `1px solid ${Colors.grey}`,
                                  borderRadius: "4px",
                                  height: "40.5px",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    handleMaidDecrease();
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "4px 0px 0px 4px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    p: "5px 8px !important",
                                    height: "100%",
                                    borderRight: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "4px 0px 0px 4px",
                                  }}
                                >
                                  -
                                </Button>
                                <TextField
                                  size="small"
                                  value={numberOfMaids}
                                  inputProps={{
                                    readOnly: true,
                                    min: 1,
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    flex: 1,
                                    width: "100%",
                                    "& fieldset": { border: "none" },
                                  }}
                                />
                                <Button
                                  onClick={() => {
                                    handleMaidIncrease();
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "0px 4px 4px 0px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    height: "100%",
                                    p: "5px 8px !important",
                                    borderLeft: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "0px 4px 4px 0px",
                                  }}
                                >
                                  +
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          {/* Select Maids */}

                          {/* Select Bedroom */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            display={
                              selectedService?.name === "Deep Cleaning" ||
                              selectedService?.name ===
                                "AirBnB/Rental Home Cleaning"
                                ? "block"
                                : "none"
                            }
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                Number of Bedrooms
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: `1px solid ${Colors.grey}`,
                                  borderRadius: "4px",
                                  height: "40.5px",
                                }}
                              >
                                <Button
                                  onClick={handleBedroomDecrease}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "4px 0px 0px 4px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    p: "5px 8px !important",
                                    height: "100%",
                                    borderRight: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "4px 0px 0px 4px",
                                  }}
                                >
                                  -
                                </Button>
                                <TextField
                                  size="small"
                                  value={
                                    bedroom > 1
                                      ? `${bedroom} bedrooms`
                                      : `${bedroom} bedroom`
                                  }
                                  inputProps={{
                                    readOnly: true,
                                    min: 1,
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    flex: 1,
                                    width: "100%",
                                    "& fieldset": { border: "none" },
                                  }}
                                />
                                <Button
                                  onClick={handleBedroomIncrease}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "0px 4px 4px 0px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    height: "100%",
                                    p: "5px 8px !important",
                                    borderLeft: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "0px 4px 4px 0px",
                                  }}
                                >
                                  +
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          {/* Select Bedroom */}

                          {/* Select Bathroom */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            display={
                              selectedService?.name === "Deep Cleaning" ||
                              selectedService?.name ===
                                "AirBnB/Rental Home Cleaning"
                                ? "block"
                                : "none"
                            }
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                Number of Bathrooms
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  border: `1px solid ${Colors.grey}`,
                                  borderRadius: "4px",
                                  height: "40.5px",
                                }}
                              >
                                <Button
                                  onClick={handleBathroomDecrease}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "4px 0px 0px 4px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    p: "5px 8px !important",
                                    height: "100%",
                                    borderRight: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "4px 0px 0px 4px",
                                  }}
                                >
                                  -
                                </Button>
                                <TextField
                                  size="small"
                                  value={
                                    bathroom > 1
                                      ? `${bathroom} bathrooms`
                                      : `${bathroom} bathroom`
                                  }
                                  inputProps={{
                                    readOnly: true,
                                    min: 1,
                                    style: {
                                      textAlign: "center",
                                    },
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                    flex: 1,
                                    width: "100%",
                                    "& fieldset": { border: "none" },
                                  }}
                                />
                                <Button
                                  onClick={handleBathroomIncrease}
                                  sx={{
                                    cursor: "pointer",
                                    ":hover": {
                                      backgroundColor: "secondary.main",
                                      borderRadius: "0px 4px 4px 0px",
                                      color: "white",
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "10%",
                                    height: "100%",
                                    p: "5px 8px !important",
                                    borderLeft: `1px solid ${Colors.grey}`,
                                    fontSize: "18px",
                                    borderRadius: "0px 4px 4px 0px",
                                  }}
                                >
                                  +
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                          {/* Select Bathroom */}

                          {/* Select Sqft */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            display={
                              selectedService?.name === "Office Cleaning"
                                ? "block"
                                : "none"
                            }
                          >
                            <Box>
                              <Typography variant="subtitle2">
                                Number of Sq. Ft.
                              </Typography>
                              <TextField
                                fullWidth
                                type="number"
                                size="small"
                                label="Number of Sq. Ft."
                                value={noOfSqFt}
                                inputProps={{
                                  min: 1,
                                }}
                                {...register("sqft", {
                                  required:
                                    "Please provide square foot of your office",
                                  onChange: (e) => setNoOfSqFt(e.target.value),
                                })}
                                error={errors?.sqft}
                                helperText={errors?.sqft?.message}
                              />
                            </Box>
                          </Grid>
                          {/* Select Sqft */}

                          {/* Select Date */}
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography variant="subtitle2">
                                When would you like us to come?
                              </Typography>
                              <FormControl
                                fullWidth
                                {...register("date", {
                                  required:
                                    selectedDate.length === 0
                                      ? "Please select a date"
                                      : false,
                                })}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    theme={theme}
                                    disablePast
                                    disableHighlightToday
                                    minDate={dayjs().add(1, "day")}
                                    onChange={(date) => {
                                      const formattedDate =
                                        dayjs(date).startOf("D");
                                      setSelectedDate([formattedDate]);
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                        error:
                                          selectedDate.length === 0 &&
                                          errors?.date,
                                        helperText:
                                          selectedDate.length === 0 &&
                                          errors?.date?.message,
                                      },
                                      popper: {
                                        style: {
                                          zIndex: 99900,
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </FormControl>
                            </Box>
                          </Grid>
                          {/* Select Date */}

                          {/* Select Time */}
                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Box>
                              <Typography variant="subtitle2">
                                Choose Time
                              </Typography>
                              <TextField
                                select
                                fullWidth
                                label={"Time"}
                                size={"small"}
                                SelectProps={{
                                  MenuProps: { style: { zIndex: 999999 } },
                                }}
                                {...register("slotTime", {
                                  required: "Please select an arrival time",
                                  onChange: (e) => handleTimeSlot(e),
                                })}
                                error={
                                  selectedTimeSlot === null && errors?.slotTime
                                }
                                helperText={
                                  selectedTimeSlot === null &&
                                  errors?.slotTime?.message
                                }
                              >
                                {timeSlots.map((item, i) => (
                                  <MenuItem key={i} value={item.id}>
                                    {`${item.slot} ${item.arrivalTime}`}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Grid>
                          {/* Select Time */}
                        </Grid>
                        {/* Maid and Date */}

                        {/* Select Material */}
                        <Box>
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "16px",
                              },
                            }}
                            control={
                              <Checkbox
                                value={material}
                                onChange={(e) => {
                                  setMaterial(e.target.checked);
                                }}
                              />
                            }
                            label="Do you want to bring us own materials"
                          />
                        </Box>
                        {/* Select Material */}
                      </Box>
                    </Grid>
                  )}
                  {/* Step 2 Gathering service information */}

                  {/* Step 3 Gathering customer information */}
                  {activeStep === 2 && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        component={Paper}
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: "18px",
                        }}
                      >
                        {/* Heading */}
                        <Box sx={{ textAlign: "center" }}>
                          <Typography variant={"h5"}>
                            Complete your booking
                          </Typography>
                        </Box>
                        {/* Heading */}
                        <Divider />
                        <Box>
                          <Typography variant={"h6"}>
                            Contact Information
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="First Name"
                              type="text"
                              size="small"
                              register={register("firstName", {
                                required: "Please enter your first name",
                              })}
                              error={errors?.firstName && true}
                              helperText={errors?.firstName?.message}
                            />
                          </Grid>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="Last Name"
                              type="text"
                              size="small"
                              register={register("lastName", {
                                required: "Please enter your last name",
                              })}
                              error={errors?.lastName}
                              helperText={errors?.lastName?.message}
                            />
                          </Grid>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="Email"
                              type="email"
                              size="small"
                              register={register("email", {
                                required: "Please enter your email",
                              })}
                              error={errors?.email}
                              helperText={errors?.email?.message}
                            />
                          </Grid>
                          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="Contact Number"
                              type="text"
                              size="small"
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "^[0-9]+$",
                                maxLength: 10,
                                minLength: 9,
                              }}
                              register={register("contact", {
                                required: "Please enter your contact number",
                                pattern: {
                                  value: "^[0-9]+$",
                                  message: "Please enter a valid number",
                                },
                                maxLength: 10,
                                minLength: 9,
                              })}
                              error={errors?.contact}
                              helperText={errors?.contact?.message}
                              // code={"+971"}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                        <Box>
                          <Typography variant={"h6"}>
                            Service Address
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              label="Service Address"
                              variant="outlined"
                              type="text"
                              size="small"
                              register={register("serviceAddress", {
                                required: "Please enter your address",
                              })}
                              error={errors?.serviceAddress}
                              helperText={errors?.serviceAddress?.message}
                            />
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="Apt/Suite"
                              type="text"
                              size="small"
                              register={register("aptSuite", {
                                required: "apt. or suite no.",
                              })}
                              error={errors?.aptSuite}
                              helperText={errors?.aptSuite?.message}
                            />
                          </Grid>
                          <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="City"
                              type="text"
                              size="small"
                              register={register("city", {
                                required: "Please enter your city",
                              })}
                              error={errors?.city}
                              helperText={errors?.city?.message}
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="State"
                              type="text"
                              size="small"
                              register={register("state", {
                                required: "Please enter your state",
                              })}
                              error={errors?.state}
                              helperText={errors?.state?.message}
                            />
                          </Grid>
                          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                            <InputField
                              fullWidth
                              variant="outlined"
                              label="Zip Code"
                              type="number"
                              size="small"
                              register={register("zip", {
                                required: "Enter your zip",
                              })}
                              error={errors?.zip}
                              helperText={errors?.zip?.message}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                  {/* Step 3 Gathering customer information */}

                  {/* Step 4 Payment Information */}
                  {activeStep === 3 && (
                    <Grid item xs={12} sm={12} md={6}>
                      <Box
                        component={Paper}
                        sx={{
                          p: 2,
                          display: "flex",
                          flexDirection: "column",
                          gap: "18px",
                        }}
                      >
                        {/* Heading */}
                        <Box sx={{ textAlign: "center" }}>
                          <Typography variant={"h5"}>
                            Complete your booking
                          </Typography>
                        </Box>
                        {/* Heading */}
                        <Divider />
                        <Box>
                          <Typography variant={"h6"}>
                            Payment Information
                          </Typography>
                        </Box>
                        <FormControl component={"fieldset"}>
                          <RadioGroup
                            value={paymentMethod ? paymentMethod.id : ""}
                            onChange={(event) => {
                              const selectedId = parseFloat(event.target.value);
                              const selectedObject = paymentOptions.find(
                                (option) => option.id === selectedId
                              );
                              setPaymentMethod(selectedObject);
                            }}
                          >
                            <Grid container spacing={2}>
                              {paymentOptions.map((option, i) => (
                                <Grid key={i} item md={2}>
                                  <FormControlLabel
                                    value={option.id}
                                    control={
                                      <Radio style={{ display: "none" }} />
                                    }
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      width: "100%",
                                      m: 0,
                                      "& .MuiFormControlLabel-label": {
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "5px",
                                      },
                                      textAlign: "center",
                                    }}
                                    label={
                                      <Fragment>
                                        <Box
                                          sx={{
                                            width: "80px",
                                            height: "80px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: `1px solid ${Colors.primary}`,
                                            borderRadius: "26px",
                                            backgroundColor:
                                              paymentMethod?.id === option.id
                                                ? Colors.primary
                                                : "transparent",
                                            transition: "all .3s ease-in-out",
                                          }}
                                        >
                                          {React.cloneElement(option.icon, {
                                            sx: {
                                              fontSize: "32px",
                                              color:
                                                paymentMethod?.id === option.id
                                                  ? Colors.white
                                                  : Colors.primary,
                                            },
                                          })}
                                        </Box>
                                        <Typography variant="caption">
                                          {option.option}
                                        </Typography>
                                      </Fragment>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                        <Divider />
                        <Box>
                          <Typography variant={"h6"}>
                            Service Comments
                          </Typography>
                        </Box>
                        <Box>
                          <InputField
                            fullWidth
                            multiline
                            rows={3}
                            register={register("comments")}
                            label={
                              "List special request of instructions for your cleaner"
                            }
                          />
                        </Box>
                        <Divider />
                        <Box>
                          <Typography variant={"h6"}>
                            Terms & Conditions
                          </Typography>
                        </Box>
                        <Box>
                          <FormControlLabel
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: "16px",
                              },
                            }}
                            control={
                              <Checkbox
                                onChange={handleCheckboxChange}
                                checked={tAndC}
                              />
                            }
                            label={
                              <Typography variant="caption">
                                I have read and agree to the{" "}
                                <b>Terms and Conditions</b>
                              </Typography>
                            }
                          />
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  {/* Step 4 Payment Information */}

                  {/* Booking Summary */}
                  {activeStep > 0 && (
                    <Grid item md={4}>
                      <Box
                        component={Paper}
                        sx={{
                          px: 2,
                          height: activeStep !== 3 ? "100%" : "auto",
                        }}
                      >
                        <Box sx={{ py: 2 }}>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, fontSize: "20px" }}
                          >
                            BOOKING SUMMARY
                          </Typography>
                          {/* <IconButton>
                                <Close />
                              </IconButton> */}
                        </Box>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: "5px 0",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Service Type{" "}
                            </Typography>
                            <Typography variant="subtitle2">
                              {selectedService?.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display:
                                selectedService?.name === "Deep Cleaning" ||
                                selectedService?.name ===
                                  "AirBnB/Rental Home Cleaning"
                                  ? "flex"
                                  : "none",
                              justifyContent: "space-between",
                              p: "5px 0",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Number of bedrooms
                            </Typography>
                            <Typography variant="subtitle2">{`${bedroom} ${
                              bedroom === 1 ? "bedroom" : "bedrooms"
                            }`}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display:
                                selectedService?.name === "Deep Cleaning" ||
                                selectedService?.name ===
                                  "AirBnB/Rental Home Cleaning"
                                  ? "flex"
                                  : "none",
                              justifyContent: "space-between",
                              p: "5px 0",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Number of bathrooms
                            </Typography>
                            <Typography variant="subtitle2">{`${bathroom} ${
                              bathroom === 1 ? "bathroom" : "bathrooms"
                            }`}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display:
                                selectedService?.order === 1 ||
                                selectedService?.order === 3
                                  ? "flex"
                                  : "none",
                              justifyContent: "space-between",
                              p: "5px 0",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Number of Maids{" "}
                            </Typography>
                            <Typography variant="subtitle2">
                              {numberOfMaids}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display:
                                selectedService?.name === "Office Cleaning"
                                  ? "flex"
                                  : "none",
                              justifyContent: "space-between",
                              p: "5px 0",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Number of Sq. Ft.
                            </Typography>
                            <Typography variant="subtitle2">
                              {noOfSqFt} Sq. Ft.
                            </Typography>
                          </Box>
                          {material && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                p: "5px 0",
                              }}
                            >
                              <Typography variant="subtitle2">
                                Material Price
                              </Typography>
                              <Typography variant="subtitle2">
                                {materialPrice}
                              </Typography>
                            </Box>
                          )}
                          {selectedFrequency && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                p: "5px 0",
                              }}
                            >
                              <ChangeCircle sx={{ color: Colors.primary }} />
                              <Typography variant="subtitle2">
                                {selectedFrequency}
                              </Typography>
                            </Box>
                          )}
                          {paymentMethod && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                p: "5px 0",
                              }}
                            >
                              <Paid sx={{ color: Colors.primary }} />
                              <Typography variant="subtitle2">
                                {paymentMethod?.option}
                              </Typography>
                            </Box>
                          )}
                          {selectedDate.length > 0 && (
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  p: "5px 0",
                                }}
                              >
                                <CalendarMonth sx={{ color: Colors.primary }} />
                                <Typography>
                                  {dayjs(selectedDate[0]).format("DD-MM-YYYY")}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display:
                              selectedService?.name === "Home Cleaning" ||
                              selectedService?.name ===
                                "Folding & Ironing Service" ||
                              selectedService?.name === "Office Cleaning"
                                ? "flex"
                                : "none",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography variant="subtitle2">Price </Typography>
                          <Typography variant="subtitle2">{price}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display:
                              selectedService?.name === "Deep Cleaning" ||
                              selectedService?.name ===
                                "AirBnB/Rental Home Cleaning"
                                ? "flex"
                                : "none",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography variant="subtitle2">
                            Bedroom Price
                          </Typography>
                          <Typography variant="subtitle2">
                            {bedroomPrice}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display:
                              selectedService?.name === "Deep Cleaning" ||
                              selectedService?.name ===
                                "AirBnB/Rental Home Cleaning"
                                ? "flex"
                                : "none",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography variant="subtitle2">
                            Bathroom Price
                          </Typography>
                          <Typography variant="subtitle2">
                            {bathroomPrice}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography variant="subtitle2">Taxable </Typography>
                          <Typography variant="subtitle2">{taxable}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography variant="subtitle2">VAT 5% </Typography>
                          <Typography variant="subtitle2">
                            {parseFloat(VAT).toFixed(2)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: "5px 0",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: "20px",
                              color: Colors.secondary,
                            }}
                          >
                            Total{" "}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: "20px",
                              color: Colors.secondary,
                            }}
                          >
                            {total}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  {/* Booking Summary */}

                  {/* Stepper Buttons */}
                  <Grid item md={activeStep < 1 ? 12 : 10}>
                    {selectedService && (
                      <Box sx={{ position: "relative", width: "100%", pb: 2 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            boxShadow: {
                              md: "none",
                              sm: "0px -1px 5px 1px #c4c4c4",
                              xs: "0px -1px 5px 1px #c4c4c4",
                            },
                            position: {
                              md: "static",
                              sm: "fixed",
                              xs: "fixed",
                            },
                            bottom: { md: "0", sm: 0, xs: 0 },
                            right: { md: "0", sm: 0, xs: 0 },
                            width: "100%",
                            zIndex: { sm: 999, xs: 999 },
                          }}
                        >
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{
                              mr: 1,
                              ":hover": { color: Colors.primary },
                              display: {
                                md: "flex",
                                sm: "none",
                                xs: "none",
                              },
                              alignItems: "center",
                              justifyContent: "space-between",
                              backgroundColor: Colors.white,
                              width: "100px",
                              p: 0,
                              borderRadius: "20px",
                              height: "30px",
                              boxShadow: "0px 0px 5px 1px #c4c4c4",
                            }}
                          >
                            <Box
                              sx={{
                                height: "100%",
                                width: "42px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                backgroundColor: Colors.primary,
                              }}
                            >
                              <ChevronLeft sx={{ color: Colors.white }} />
                            </Box>
                            <Box
                              sx={{
                                width: "100px",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Back
                            </Box>
                          </Button>
                          {/* Back Button for Small Device */}
                          <Button
                            sx={{
                              display: {
                                md: "none",
                                sm: "block",
                                xs: "block",
                              },
                              color: Colors.primary,
                              fontSize: "14px",
                            }}
                            // onClick={() => setState(true)}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <b>Total</b>
                              <Box component={"span"} sx={{ display: "flex" }}>
                                {/* {` AED ${grandTotal.toFixed(2)}`} */}
                                <KeyboardArrowUp />
                              </Box>
                            </Box>
                          </Button>
                          {/* Back Button for Small Device */}

                          <Button
                            type="submit"
                            // onClick={handleNext}
                            sx={{
                              ":hover": { color: Colors.primary },
                              alignItems: "center",
                              backgroundColor: Colors.white,
                              width: "100px",
                              p: 0,
                              justifyContent: "space-between",
                              borderRadius: "20px",
                              height: "30px",
                              boxShadow: "0px 0px 5px 1px #c4c4c4",
                            }}
                            disabled={
                              activeStep === steps.length - 1 &&
                              submitDisabled &&
                              !tAndC
                            }
                          >
                            <Box
                              sx={{
                                width: "100px",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {activeStep === steps.length - 1
                                ? "Submit"
                                : "Next"}
                            </Box>
                            <Box
                              sx={{
                                height: "100%",
                                width: "42px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                backgroundColor: Colors.primary,
                              }}
                            >
                              <ChevronRight sx={{ color: Colors.white }} />
                            </Box>
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  {/* Stepper Buttons */}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
      {/* Body */}
    </Box>
  );
}

export default Booking;
