import { TextField } from "@mui/material";

function InputField(props) {
  const {
    fullWidth,
    variant,
    label,
    placeholder,
    size,
    type,
    register,
    error,
    helperText,
    inputProps,
    inputMode,
    multiline,
    rows,
  } = props;
  return (
    <TextField
      fullWidth={fullWidth}
      variant={variant}
      label={label}
      placeholder={placeholder}
      type={type}
      size={size}
      multiline={multiline}
      rows={rows}
      inputProps={inputProps}
      inputMode={inputMode}
      {...register}
      error={error}
      helperText={helperText}
    />
  );
}

export default InputField;
