import React, { Fragment, useState, useEffect } from "react";
import {
  Paper,
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TablePagination,
  Typography,
  Collapse,
  FormControl,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
} from "@mui/material";
// import AuthService from "../../Api/Auth/AuthService";
// import ServicesService from "../../Api/Services/ServicesService";
import moment from "moment";
import {
  FilterAlt,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Visibility,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { Colors } from "../../Assets/Styles/Colors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/UseContext";

function DataFilter({ sortData, page, rows }) {
  const [filterCollapse, setFilterCollapse] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedId, setSelectedId] = useState("");
  console.log("🚀 ~ file: Orders.js:41 ~ DataFilter ~ selectedId:", selectedId);
  const [search, setSearch] = useState("");
  const [services, setServices] = useState([]);

  const filterData = () => {
    sortData(page, rows, search, selectedId, fromDate, toDate);
  };

  const getServices = async () => {
    // try {
    //   const { data } = await ServicesService.getService();
    //   const filteredData = data?.services?.map((service) => ({
    //     id: service?._id,
    //     name: service?.name,
    //   }));
    //   setServices(filteredData);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const clearData = () => {
    sortData("");
    setSelectedId("");
    setFromDate("");
    setToDate("");
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <Box sx={{ px: 1 }}>
      <Box
        component={Paper}
        sx={{ bgcolor: Colors.white, p: 2, borderRadius: "12px", mt: 5 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" fontWeight="bold" color="initial">
            Filters
          </Typography>
          <IconButton
            onClick={() => setFilterCollapse(!filterCollapse)}
            color="primary"
            sx={{ bgcolor: Colors.primaryLight }}
          >
            <FilterAlt />
          </IconButton>
        </Box>

        <Collapse in={filterCollapse}>
          <Grid container spacing={2} justifyContent="space-between">
            <Fragment>
              <Grid item md={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label={"Services"}
                    size={"small"}
                    value={selectedId}
                    onChange={(e) => {
                      if (e.target.value === "") return;
                      else {
                        setSelectedId(e.target.value);
                      }
                    }}
                  >
                    <MenuItem value={""}>Services</MenuItem>
                    {services.map((service, i) => (
                      <MenuItem key={i} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl size="small" fullWidth variant="outlined">
                  <TextField
                    label="From Date"
                    size={"small"}
                    variant="outlined"
                    type="date"
                    value={moment(fromDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true, required: true }}
                    inputProps={{ max: toDate }}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setFromDate(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl size="small" fullWidth variant="outlined">
                  <TextField
                    label="To Date"
                    size={"small"}
                    variant="outlined"
                    type="date"
                    inputProps={{ min: fromDate }}
                    value={moment(toDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true, required: true }}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Fragment>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                onClick={clearData}
                variant="outlined"
                sx={{
                  mx: 1,
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                }}
              >
                Clear
              </Button>
              <Button
                onClick={filterData}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  minWidth: "100px",
                  boxShadow:
                    "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
                  color: Colors.white,
                }}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </Box>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme;
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}

function Orders() {
  const tableHead = [
    "Order ID",
    "Name",
    "E-mail",
    "Phone",
    "Service",
    "Order Date",
    "Action",
  ];
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getOrders(newPage + 1, limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(Math.floor(event.target.value));
    setPage(0);
    getOrders(1, event.target.value);
  };

  const getOrders = async (
    newPage,
    rows,
    search,
    serviceId,
    fromDate,
    toDate
  ) => {
    // setLoading(true);
    // try {
    //   const { data, responseCode } = await AuthService.getOrders(
    //     newPage ? newPage : 1,
    //     rows ? rows : "",
    //     search ? search : "",
    //     serviceId ? serviceId : "",
    //     fromDate ? fromDate : "",
    //     toDate ? toDate : ""
    //   );
    //   if (responseCode === 200) {
    //     setOrders(data?.queries);
    //     setCount(data?.total);
    //   }
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   setLoading(false);
    // }
  };

  const sortByStatus = (page, rows, search, serviceId, fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLimit(10);
    setPage(0);
    getOrders(
      page,
      rows,
      search ? search : "",
      serviceId ? serviceId : null,
      fromDate ? moment(fromDate).utc() : null,
      toDate ? moment(toDate).utc() : null
    );
  };

  useEffect(() => {
    getOrders();
  }, []);
  return (
    <Box>
      <DataFilter
        sortData={(page, rows, search, serviceId, fromDate, toDate) =>
          sortByStatus(page, rows, search, serviceId, fromDate, toDate)
        }
        page={page}
        rows={limit}
      />
      <Box sx={{ p: 1 }}>
        {loading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              p: 2,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <TableContainer
              component={Paper}
              sx={{
                "&::-webkit-scrollbar": {
                  bgcolor: Colors.white,
                  height: "8px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: Colors.primary,
                  borderRadius: "10px",
                  cursor: "pointer",
                },
                "&.MuiPaper-root": {
                  borderRadius: "12px",
                  mt: 2,
                },
                maxHeight: "310px",
              }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableHead.map((cell, i) => (
                      <TableCell key={i}>{cell}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((data, i) => (
                    <TableRow key={i}>
                      <TableCell>{data?._id}</TableCell>
                      <TableCell>{data?.contact?.name}</TableCell>
                      <TableCell>{data?.contact?.email}</TableCell>
                      <TableCell>+971{data?.contact?.number}</TableCell>
                      <TableCell>{data?.summary?.name}</TableCell>
                      <TableCell>
                        {moment(data?.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigate("/orderdetails", { state: data })
                          }
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 3 }}>
              <Table component={Paper} sx={{ borderRadius: "12px" }}>
                <TablePagination
                  sx={{
                    borderBottom: "none",
                    bgcolor: Colors.white,
                    borderRadius: "12px",
                  }}
                  rowsPerPageOptions={[10, 20, 30]}
                  colSpan={12}
                  count={count === undefined ? 0 : +count}
                  rowsPerPage={limit}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </Table>
            </Box>
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

export default Orders;
